import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ContentBox from "../components/content-box"
import PageHeader from "../components/headers/page-header"
import { css } from "@emotion/core"
import SectionHeader from "../components/headers/section-header"
import TapeLabelHeader from "../components/headers/tape-label-header"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import _ from 'lodash'

class ResearchTemplate extends React.Component {
  render() {
    const { slug } = this.props.pageContext
    const { research, post, related } = this.props.data

    if (!post) {
      console.error(slug)
      return null
    }

    let isMainTopic
    // if no topics, assume this is a main topic
    if (!post.frontmatter.topics) {
      isMainTopic = true
    }

    let relatedComicPages = _.orderBy(_.filter(related.edges, {node: {frontmatter: {type: "comic-page"}}}), [
      // first, sort for comic (title)
      'node.frontmatter.comic',
      // then sort by seriesPage (number)
      'node.frontmatter.seriesPage',
      ], ['asc', 'asc'])

    let relatedBlogPosts = _.filter(related.edges, {node: {frontmatter: {type: "blog"}}})

    return (
      <Layout location={this.props.location}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
        />

        <PageHeader>{post.frontmatter.title}</PageHeader>
        <ContentBox>
          {(post.html || post.frontmatter.description) && <section css={css`margin-bottom:20px;`} dangerouslySetInnerHTML={{ __html: post.html || post.frontmatter.description }}/>}

          {related.edges.length > 0 &&
          <div>

            {relatedComicPages.length > 0 && <div>
              <SectionHeader align={'left'}>Comics</SectionHeader>
              <ul>
                {relatedComicPages.map(({ node }) => {
                  return (
                    <li key={node.fields.slug}><Link to={node.fields.slug} title={node.frontmatter.title} >{`${node.frontmatter.comic} – ${node.frontmatter.issue} – page ${node.frontmatter.page}`}</Link></li>
                  )
                })}
              </ul>
            </div>}

            {relatedBlogPosts.length > 0 && <div>
              <SectionHeader align={'left'}>Blog Posts</SectionHeader>
              <ul>
                {relatedBlogPosts.map(({ node }) => {
                  return (
                    <li key={node.fields.slug}><Link to={node.fields.slug} title={node.frontmatter.title}>{node.frontmatter.title}</Link></li>
                  )
                })}
              </ul>
            </div>}

          </div>}

          {post.frontmatter.links &&
          <div>
            <SectionHeader align={"left"}>Further Knowledge</SectionHeader>
            <p>Here are some suggested books and films about this topic. Not all of them are easy to obtain, but we've tried to link places to find them.</p>
            {post.frontmatter.links.map(({ title, link, description, img }) => {
              return (
                <div
                  key={link}
                  css={css`
                  :before, :after {
                    content: '';
                    display: table;
                    clear: both;
                  }
                  
                  a {
                    &:hover { 
                      color: inherit;
                    }
                  }
                  
                  margin-bottom:20px;
                  
                  .img {
                    float:left;
                    margin-right:20px;
                    width: 100px;
                  }`}>
                  <OutboundLink target={"_blank"} href={link}>
                    {img && <Img fixed={img.childImageSharp.fixed} className={"img"}/>}
                    <h4>{title}</h4>
                  </OutboundLink>
                  <p>{description}</p>
                </div>
              )
            })

            }
          </div>}

        </ContentBox>

        <div css={css`display:flex;flex-direction: row;flex-wrap: wrap;justify-content: space-around;`}>
          {isMainTopic && research.edges.map(({ node }) => {

            const { title, topics, image, excerpt, description } = node.frontmatter

            // main topic, so skip
            if (!topics) return null

            if (topics.includes(post.frontmatter.topic)) {
              return (
                <div className={"drop-shadow"} css={css`
                background: white;
                display:flex;
                flex-direction: row;
                flex: 0 48%;
                min-width:450px;
                margin-bottom: 20px;
              `}>
                  {image && <Link to={node.fields.slug} title={title}><Img fluid={image.childImageSharp.fluid}
                                                             css={css`min-width:150px;height:100%;`}/></Link>}
                  <div>
                    <Link to={node.fields.slug} title={title}><TapeLabelHeader>{title}</TapeLabelHeader></Link>
                    <p css={css`padding:10px;`}>
                      {description || excerpt}
                    </p>
                  </div>
                </div>
              )
            } else {
                return null
            }
          })}
        </div>
      </Layout>
    )
  }
}

export default ResearchTemplate

export const pageQuery = graphql`
    query ResearchBySlug($slug: String!, $topic: String) {
        post: markdownRemark(fields: { slug: { eq: $slug } }) {
            id
            excerpt(pruneLength: 160)
            html
            frontmatter {
                title
                description
                topic
                topics
                links {
                    title
                    img {
                        childImageSharp {
                            fixed(width: 150) {
                                ...GatsbyImageSharpFixed
                            }
                        }
                    }
                    link
                    description
                }
            }
        }

        related: allMarkdownRemark(filter: {frontmatter: {research: {in: [$topic], ne: null}}}) {
            edges {
                node {
                    fields {
                        slug
                    }
                    frontmatter {
                        title
                        comic
                        issue
                        seriesPage
                        page
                        type
                    }
                }
            }
        }

        research: allMarkdownRemark(filter: {frontmatter: {type: {eq: "research"}}}) {
            edges {
                node {
                    fields {
                        slug
                    }
                    html
                    excerpt(pruneLength: 160)
                    frontmatter {
                        date(formatString: "MMMM DD, YYYY")
                        title
                        description
                        topic
                        topics
                        image {
                            name
                            childImageSharp {
                                fluid(maxWidth: 300) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`
